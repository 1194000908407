import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="App-content">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Music & Entertainment</p>
      </div>
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} Goodrich House, LLC.</p>
      </footer>
    </div>
  );
}

export default App;
